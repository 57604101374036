import React from "react";
import AliceCarousel from "react-alice-carousel";
import styles from "./carousel.module.scss";
import SliderCard from "../slider-card";
import BoxImage from "../slider-images/box-image";
import {
  optionsSlider,
  responsive,
  sliderItems,
} from "../../constants/homepage";

function Carousel() {
  return (
    <div className={styles.sliderSectionWrapper}>
      <div className={styles.sliderSection}>
        <div className={styles.notSliderCard}>
          <SliderCard
            image={<BoxImage className={styles.image} />}
            title={"Bežná preprava zásielok"}
            description={"Prepravíme v rámci Košíc alebo do Česka"}
          />
        </div>
        <div className={styles.separator}>
          <span>ALEBO</span>
        </div>
        <div className={styles.slider}>
          <AliceCarousel
            mouseTracking
            items={sliderItems}
            responsive={responsive}
            infinite
            {...optionsSlider}
            autoWidth
          />
        </div>
      </div>
    </div>
  );
}

export default Carousel;
